<template>
    <template v-if="node.isLoading">
        <a class = "d-block p-3 border-bottom-1 w-100 text-black orgunit border-bottom">
            {{ $t("Loading...")}} 
            <div class="spinner-border spinner-border-sm align-self-center" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </a> 
    </template>

    <template v-else-if="node.hasNodes">
        <a class = "d-block p-3 border-bottom-1 w-100 text-black orgunit border-bottom" @click="expandFolder(node)" :title ="'ID: ' + node.ID + ', ' + node.OrgUnit">
            {{ node.OrgUnit }} ({{ node.details.length }})
        </a>    
    </template>

    <template v-else>
        <a class = "d-block p-3 border-bottom-1 w-100 text-black orgunit border-bottom" @click="expandFolder(node)" :title ="'ID: ' + node.ID + ', ' + node.OrgUnit">
            {{ node.OrgUnit }}
        </a>    
    </template>
</template>

<script setup lang="ts">
import { NodeItem } from "o365-nodedata";
import { $t } from "o365-utils"
import { defineProps, defineEmits } from "vue";

const props = defineProps<{
    node: NodeItem;
}>();

const emits = defineEmits<{
    expandFolder: [node: NodeItem, isCalledFromBreadCrumb?: boolean];
}>()

const expandFolder = (node: NodeItem, isCalledFromBreadCrumb?: boolean) => {
    emits("expandFolder", node, false);
}

</script>

<style scoped>
.orgunit {
    display: flex;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: flex;
    align-items: center;
}

</style>